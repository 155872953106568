import { atom } from "jotai";
import { customAlphabet } from "nanoid";
import localDBInstance from "../Data/LocalDB";

// subject hierarchy with .
// const base = "ni";
// const app = "a0X5";
// const discoHello = "hello"; // public hello on connect
// const discoBye = "bye"; // public goodbye on disconnect
// const nodes = "nodes";

// ni tether is built on
// ni.system    -> subscribe/publish system messages
// ni.{appID}   -> subscribe/publish app specific message
// ni.{appID}.hello -> publish hello
// ni.{appID}.bye   -> publish goodbye
// ni.{appID}.{clientTransientId}.nodes.{nodeID} -> channel for internode msg

// Nats.io safe subject characters
const nanoid = customAlphabet(
  "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  10
);

const getClientTransientId = async (clientDataTable) => {
  let key = null;
  let clientTransientId = null;
  try {
    key = await clientDataTable.getItem("clientTransientKey");
    if (key === null) {
      clientTransientId = nanoid();
      await clientDataTable.setItem("clientTransientKey", clientTransientId);
      return {
        key: clientTransientId,
        hasError: false,
      };
    } else {
      return {
        key: key,
        hasError: false,
      };
    }
  } catch (err) {
    return {
      key: null,
      hasError: true,
      errorObj: err,
    };
  }
};

const asyncClientIdAtom = atom(async (get) => {
  const db = localDBInstance.getAll();
  const clientTransientId = await getClientTransientId(db.clientTable);
  return clientTransientId;
});

export const ClientTransientId = atom(async (get) => get(asyncClientIdAtom) )