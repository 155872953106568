import React from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import { CheveronDownIcon } from "../Icons";

// Available panels
const ConnectionModule = React.lazy(() => import("../Modules/MsgBus"));

// Section is loading
const SectionLoading = () => {
  return (
    <div className="flex content-center flex-grow">
      <p></p>
    </div>
  );
};

const Console = () => {
  const [isVisible, toggleVisibility] = React.useState(false);

  const openAndCloseAnimation = useSpring({
    loop: false,
    to: {
      transform: isVisible ? "translateY(0%)" : "translateY(100%)",
    },
    config: {
      duration: 250,
      easing: easings.easeInOutQuint,
    },
  });

  const rotateChevronAnimation = useSpring({
    loop: false,
    to: {
      transform: isVisible ? "rotate(180deg)" : "rotate(0deg)",
    },
    config: {
      duration: 250,
      easing: easings.easeInOutQuint,
    },
  });

  return (
    <animated.div
      className="fixed z-40 flex flex-col flex-grow w-full console-container"
      style={openAndCloseAnimation}
    >
      {/* START TABS */}
      <div className="absolute left-0 flex flex-row -top-9 h-9">
        <div
          className="flex w-9 h-9 text-white rotate-180 cursor-pointer bg-sky-800 bg-opacity-90"
          onClick={(e) => {
            e.preventDefault();
            toggleVisibility(!isVisible);
          }}
        >
          <animated.div
            className={"flex w-9 h-9 items-center justify-center"}
            style={rotateChevronAnimation}
          >
            <CheveronDownIcon className="w-5 h-5" />
          </animated.div>
        </div>
        <div className="flex flex-row p-2 bg-zinc-200 items-center">
          <p className="text-xs text-zinc-400">messaging</p>
        </div>
      </div>
      {/* END TABS */}
      <div className="flex flex-grow bg-zinc-100 bg-opacity-90 shadow-inner shadow-zinc-400">
        <React.Suspense fallback={<SectionLoading />}>
          <ConnectionModule />
        </React.Suspense>
      </div>
    </animated.div>
  );
};

export default Console;
