import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { useAtom } from "jotai";

import {
  BxLockAltIcon,
  BxLockOpenAltIcon,
  IconSquareGithub,
  IconBxlInstagram,
  IconLinkedin,
} from "../../Icons";

import LogoMark from "../../Assets/logo_mark36x36.png";
import { supabase } from "../../Utilities/Supabase/client";
import MasterMenu from "../MasterMenu";

const siteLockNavigate = (e, navigate, currentLocation) => {
  e.preventDefault();
  if (currentLocation !== "/authenticate") {
    navigate("/authenticate");
  }
};

// const MasterMenu = (v) => <div></div>;

/*
  React.useEffect(() => {
    console.log("[CID] -> ", clientTransientId);
  }, [clientTransientId]);
  */

const Header = () => {
  const [session, setSession] = useState(null);
  let navigate = useNavigate();
  let userTile = <span />;
  let location = useLocation();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  let isAuthenticated = session ? session.user.role === "authenticated" : false;

  // console.log("SESSION", isAuthenticated, session);
  let lockStyle = isAuthenticated
    ? " bg-sky-800 bg-opacity-95"
    : " bg-sky-800 bg-opacity-95";
  return (
    <header className="fixed z-50 flex flex-col items-center w-full">
      <div className="relative flex flex-col flex-grow w-full h-20 bg-opacity-75 border-b md:max-w-4xl bg-slate-300">
        <div className="absolute bottom-0 flex flex-row w-full h-px shadow-sm opacity-20 shadow-neutral-900"></div>

        <div className="flex flex-row items-center w-full h-full p-4 pl-2 pr-2">
          <div
            className="flex items-center justify-center mr-2 rounded-full cursor-pointer"
            onClick={(e) => navigate("/")}
          >
            <img
              className="border rounded-full shadow-sm bg-zinc-50"
              src={LogoMark}
              alt="logo"
            />
          </div>
          <div className="flex flex-row h-9">
            <div className="flex flex-row w-9 h-9 bg-sky-800 hover:bg-slate-700 rounded-tl-md rounded-bl-md">
              <MasterMenu />
            </div>
            <div
              className={
                "flex items-center justify-center p-2 transition-colors duration-150 border-l cursor-pointer h-9 border-sky-900 hover:bg-slate-700" +
                lockStyle
              }
              onClick={(e) => {
                siteLockNavigate(e, navigate, location.pathname);
              }}
            >
              {isAuthenticated ? (
                <BxLockOpenAltIcon className="w-5 text-white" />
              ) : (
                <BxLockAltIcon className="w-5 text-white" />
              )}
            </div>
            <p className="self-start p-2 pl-4 pr-4 text-gray-300 text-md h-9 bg-slate-700 font-lekton">
              NAME·INDUSTRY
            </p>
          </div>
          {userTile}
          <div className="flex flex-row justify-end flex-grow pr-2">
            <div className="flex flex-row pr-0.5">
              <a
                href="https://www.linkedin.com/in/alexander-davis-b74756257"
                alt="linkdin account"
              >
                <IconLinkedin className="flex w-6 h-full text-slate-500" />
              </a>
            </div>
            <div className="flex flex-row pr-1">
              <a
                href="https://www.instagram.com/alexander.0x"
                alt="instagram account"
              >
                <IconBxlInstagram className="flex w-6 h-full text-slate-500" />
              </a>
            </div>
            <div className="flex flex-row pr-1">
              <a href="https://github.com/name-industry" alt="github account">
                <IconSquareGithub className="flex w-5 h-full text-slate-500" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
