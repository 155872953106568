import { Suspense, lazy } from "react";
import { createBrowserRouter, useRouteError } from "react-router-dom";
import Section404 from "../../Screens/Section404";
import { ApplicationShell } from "../../Shell";


const lazyLoadRoutes = (componentName) => {
  const LazyElement = lazy(() => import(`../../Screens/${componentName}`));
  return (
    <Suspense fallback={<span />}>
      <LazyElement />
    </Suspense>
  );
};


const options = {
  basename: "/",
  future: {},
};

const ErrorBoundaryProject = () => {
  let error = useRouteError();
  return <></>;
}

// const ApplicationShell = () => <div></div>;
// const lazyLoadRoutes = (v) => <div></div>;

/*
const routes = [
  {
    path: "/",
    element: <ApplicationShell />,
  },
];
*/

/*
const routes = [
  {
    path: "/",
    element: <ApplicationShell />,
    children: [
      {
        path: "/",
        lazy: () => import("../../Screens/Landing"),
      },
    ],
  },
];
*/

const routes = [
  {
    path: "/",
    element: <ApplicationShell />,
    errorElement: <ErrorBoundaryProject />,
    children: [
      {
        path: "/",
        element: lazyLoadRoutes("Landing.js"),
      },
      {
        path: "/about",
        element: lazyLoadRoutes("About.js"),
      },
      {
        path: "/works",
        element: lazyLoadRoutes("Works.js"),
      },
      {
        path: "/works/:projectId",
        element: lazyLoadRoutes("WorkDetail.js"),
      },
      {
        path: "/authenticate",
        element: lazyLoadRoutes("Authenticate.js"),
      },
      {
        path: "/terms",
        element: lazyLoadRoutes("Terms.js"),
      },
      {
        path: "/policy",
        element: lazyLoadRoutes("Policy.js"),
      },
      {
        path: "/dashboard",
        element: lazyLoadRoutes("Dashboard.js"),
      },
      {
        path: "*",
        element: <Section404 />,
      },
    ],
  },
];

export const MasterRouterObj = createBrowserRouter(routes, options);
