import React, { useEffect, useState } from "react";
import { MenuIcon } from "../../Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../Utilities/Supabase/client";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import "./menu.css";

const MenuItemButton = React.forwardRef((props, forwardedRef) => {
  const navigate = useNavigate();
  const location = useLocation();
  let isCurrentLocation = props.path === location.pathname;
  if (props.path === "/dashboard" && location.pathname === "/authenticate")
    isCurrentLocation = true;
  return (
    <button
      ref={forwardedRef}
      className={`${
        isCurrentLocation
          ? "bg-slate-100 text-gray-900 p-4"
          : "bg-zinc-100 text-gray-500"
      } flex flex-row mb-px items-center p-4 text-sm hover:bg-slate-200 transition duration-150 ease-out hover:ease-in`}
      onClick={(e) => {
        props.closeMenu(false);
        if (props.path !== location.pathname) {
          navigate(props.path);
        }
      }}
    >
      {props.label}
    </button>
  );
});

const MasterMenu = () => {
  const [isOpen, setOpenState] = React.useState(false);
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <DropdownMenu.Root
      modal={true}
      open={isOpen}
      onOpenChange={(isOpen) => {
        setOpenState(isOpen);
      }}
    >
      <DropdownMenu.Trigger>
        <div className="flex flex-col text-white items-center justify-center w-9 h-9 cursor-pointer">
          <MenuIcon />
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="DropdownMenuContent z-50 flex flex-col bg-white w-60 flex-grow-0 p-0.5 pb-0.5 flex-shrink-0"
          sideOffset={-2}
          alignOffset={2}
          align="start"
        >
          <DropdownMenu.Item asChild>
            <MenuItemButton label="Home" path="/" closeMenu={setOpenState} />
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <MenuItemButton
              label="About"
              path="/about"
              closeMenu={setOpenState}
            />
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <MenuItemButton
              label="Works & Experiments"
              path="/works"
              closeMenu={setOpenState}
            />
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <MenuItemButton
              label="Client Dashboard"
              path="/dashboard"
              closeMenu={setOpenState}
            />
          </DropdownMenu.Item>
          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default MasterMenu;
