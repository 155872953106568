import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigateTo = useNavigate();

  const openTerms = () => {
    navigateTo("./terms");
  };

  const openPolicy = () => {
    navigateTo("./policy");
  };

  return (
    <div className="fixed bottom-0 flex flex-row items-center justify-center w-full border-t shadow-inner border-t-zinc-300 shadow-zinc-300 h-11 bg-zinc-100 font-lekton">
      <p className="pb-1 pr-1 text-xs select-none text-zinc-400">
        Copyright <span className="text-base">©</span> 2023 - Alexander Davis
      </p>
      <p className="pb-1.5 text-xs text-zinc-400">|</p>
      <p
        className="pb-1 pl-1 pr-1 text-xs underline cursor-pointer select-none text-zinc-400"
        onClick={openTerms}
      >
        Terms
      </p>
      <p className="pb-1.5 text-xs text-zinc-400">|</p>
      <p
        className="pb-1 pl-1 text-xs underline cursor-pointer select-none text-zinc-400"
        onClick={openPolicy}
      >
        Privacy
      </p>
    </div>
  );
};

export default Footer;
