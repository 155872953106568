const setViewportProperty = (e) => {
  document
    .querySelector(":root")
    .style.setProperty("--vh", window.innerHeight / 100 + "px");
};
const saveApplicationState = (e) => {
  for (var i = 0; i < 500000000; i++) {}
  return undefined;
};

const initializeListeners = () => {
    window.addEventListener("resize", setViewportProperty);
    window.addEventListener("beforeunload", saveApplicationState);
}

export const globalListeners = {
    initializeListeners: initializeListeners,
    setViewportProperty: setViewportProperty
}