import React from "react";

const Section404 = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full h-full bg-slate-300">
      <p className="p-2 mb-px text-xs text-white bg-slate-700">
        The section you are looking for does not exist
      </p>
    </div>
  );
};

export default Section404;
