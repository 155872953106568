import React from "react";
import { connect, Empty, JSONCodec, headers } from "nats.ws";
import { useAtom } from "jotai";
import { msgBusAtom } from "../State/RemoteMsgBus";
import { ClientTransientId } from "../State/Application";
import { MSG_BUS_ENDPOINT } from "../Constants";

const MessageBusContext = React.createContext();

const generateServerConnectionObject = (clientTransientId, options = null) => {
  return {
    servers: MSG_BUS_ENDPOINT.servers,
    name: MSG_BUS_ENDPOINT.clientNamePrefix + clientTransientId,
    user: MSG_BUS_ENDPOINT.userNamePrefix + clientTransientId,
  };
};

const MessageBusProvider = ({ children }) => {
  const nc = React.useRef(null);

  const [msgBusState, setMsgBusState] = useAtom(msgBusAtom);
  const [clientTransientId] = useAtom(ClientTransientId);

  const getConnectedClients = async () => {
    const jsonCodec = JSONCodec();
    const h = headers();
    h.append("clientTransientId", clientTransientId.key);
    const list = await nc.current
      .request("ni.a0X5.disco", Empty, { timeout: 2000, headers: h })
      .then((m) => {
        return jsonCodec.decode(m.data);
      })
      .catch((err) => {
        return {
          hasError: true,
          errorMessage: err.message,
        };
      });
    return list;
  };

  const setPublicDiscovery = React.useCallback(async () => {
    const jsonCodec = JSONCodec();
    const h = headers();
    h.append("clientTransientId", clientTransientId.key);
    const makePublic = await nc.current
      .request("ni.a0X5.hello", Empty, { timeout: 2000, headers: h })
      .then((m) => {
        return jsonCodec.decode(m.data);
      })
      .catch((err) => {
        return err.message;
      });
    return makePublic;
  }, [clientTransientId]);

  const unsetPublicDiscovery = React.useCallback( 
    async () => {
      const jsonCodec = JSONCodec();
      const h = headers();
      h.append("clientTransientId", clientTransientId.key);

      let unsetResult = {};

      if(nc.current !== null) {

        unsetResult = await nc.current
        .request("ni.a0X5.bye", Empty, { timeout: 2000, headers: h })
        .then((m) => {
          return jsonCodec.decode(m.data);
        })
        .catch((err) => {
          return err.message;
        });

      }

      return unsetResult;

  }, [clientTransientId]);

  React.useEffect(() => {
    if (clientTransientId === null || clientTransientId.hasError === true ) return;
    const runAsyncEffect = async () => {
      const connectionObj = generateServerConnectionObject(clientTransientId.key);
      nc.current = await connect(connectionObj);
      let isPublic = await setPublicDiscovery();
      setMsgBusState({
        isConnected: true,
        connectionInfo: nc.current.info,
        isPublic: isPublic,
      });
    };
    runAsyncEffect();
    return () => {
      unsetPublicDiscovery()
        .then((res) => {
          // console.log("REMOVED", res);
        })
        .catch((err) => console.log(err));
    };
  }, [
    clientTransientId,
    setMsgBusState,
    nc,
    setPublicDiscovery,
    unsetPublicDiscovery,
  ]);

  const value = {
    msgBusState: msgBusState,
    getClientList: getConnectedClients,
    setPublicDiscovery: setPublicDiscovery,
    unsetPublicDiscovery: unsetPublicDiscovery,
  };

  return (
    <MessageBusContext.Provider value={value}>
      {children}
    </MessageBusContext.Provider>
  );
};

const useMessageBus = () => {
  const context = React.useContext(MessageBusContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a MessageBusProvider");
  }
  return context;
};

export { MessageBusProvider, useMessageBus };
