import { createInstance } from "@jonkoops/matomo-tracker-react";

export const MatomoSettings = createInstance({
  urlBase: "https://name-industry.com",
  siteId: 1,
  // userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: "https://name-industry.com/matomo/matomo.php",
  srcUrl: "https://name-industry.com/matomo/matomo.js",
  disabled: process.env.NODE_ENV === "development" ? true : false,
  heartBeat: {
    // optional, enabled by default
    active: false, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});
