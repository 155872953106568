import { atom } from "jotai";

const statusAtom = atom({
  isConnected: false,
  isPublic: false,
  connectionInfo: {}
});

export const msgBusAtom = atom(
  (get) => get(statusAtom),
  (get, set, isConnected) => {
    set(statusAtom, isConnected);
  },
  (get, set, isPublic) => {
    set(statusAtom, isPublic);
  },
  (get, set, connectionInfo) => {
    set(statusAtom, connectionInfo);
  }
);
