import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { MessageBusProvider } from "./Context/MessageBus";
import { MasterRouterObj } from "./Routers/Browser/MasterRouter";
import { RouterProvider } from "react-router-dom";
import { Provider } from "jotai";
import { MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { MatomoSettings } from "./Utilities/Tracking/Matomo";
import { globalListeners } from "./Utilities/Listeners/InitializeGlobals";

// Fonts supported 300, 400, 500, 600, 700, 800
// Include only required.

import "@fontsource/open-sans"; // 400
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/lekton"; // 400
import "@fontsource/lekton/700.css";
import "@fontsource/anton"; // 400
import "@fontsource/anton/400.css";

import "./index.css";

const container = document.getElementById("name-industry-application");
const root = createRoot(container);

// Split for Jotai to allow async state calls
// const ApplicationShell = React.lazy(() => import("./Shell"));

globalListeners.initializeListeners();
globalListeners.setViewportProperty();

root.render(
  <MatomoProvider value={MatomoSettings}>
    <Provider>
      <Suspense>
        <MessageBusProvider>
          <RouterProvider router={MasterRouterObj}></RouterProvider>
        </MessageBusProvider>
      </Suspense>
    </Provider>
  </MatomoProvider>
);
