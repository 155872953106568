import localForage from "localforage";

const initializeDB = () => {
  const msgStore = localForage.createInstance({
    name: "NIMsgStore",
  });
  const worksData = localForage.createInstance({
    name: "NIMsgStore",
    storeName: "worksData",
    description: "works section data persistance",
  });
  const clientData = localForage.createInstance({
    name: "NIMsgStore",
    storeName: "clientData",
    description: "per client data persistance",
  });
  const applicationData = localForage.createInstance({
    name: "NIMsgStore",
    storeName: "applicationData",
    description: "web application persistance",
  });
  return {
    masterDB: msgStore,
    clientTable: clientData,
    worksTable: worksData,
    applicationTable: applicationData,
  };
};

class localDB {
  
  // Get all the handles to the 
  // local db and its tables
  getAll() {
    return initializeDB();
  }
}

// 
const localDBInstance = new localDB();
Object.freeze(localDBInstance);

export default localDBInstance;