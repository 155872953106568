import React from "react";
// import { useLocation } from "react-router-dom";
// import { useTransition, animated, easings } from "@react-spring/web";
import { Outlet } from "react-router-dom";
import Header from "./Modules/Header";
import Console from "./Screens/Console";
import Footer from "./Modules/Footer";
import "./App.css";

/*
const SectionContainer = () => {
  const location = useLocation();
  // const currentOutlet = useOutlet();

  // console.log("[CONTAINER]", location);

  const transitions = useTransition(location.key, {
    expires: true,
    config: {
      duration: 250,
      easing: easings.easeInOutQuint,
    },
    exitBeforeEnter: false,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onStart: (r, s, i) => {
      // console.log("RENDER CALLED", r, i);
    },
    onProps: (props) => {
      // console.log("NEW PROPS CALLED", props);
    },
    onDestroyed: (item, key) => {
      // console.log("DESTROYED CALLED", item, key);
    },
  });

  return (
    <>
      {transitions((props, item) => {
        return (
          <animated.div
            className={"relative flex flex-col w-full h-full min-h-full"}
            style={props}
          >
            <Outlet />
          </animated.div>
        );
      })}
    </>
  );
};
*/

const AShell = () => {
  /*
  const Container = React.memo(() => {
    return <SectionContainer />;
  }, []);
  */

  return (
    <React.Fragment>
      <Header />
      <div className="relative w-full h-20 md:hidden" />
      <div className="relative flex flex-col flex-grow">
        <Outlet />
      </div>
      <div className="relative w-full h-11" />
      <Console />
      <Footer />
    </React.Fragment>
  );
};

export const ApplicationShell = React.memo(AShell);
