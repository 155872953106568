/*
const PUBLIC_KEY_PRODUCTION = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAzeDxEo6A4ewd0hJbay92
WQmNcqc7V8SqlCy80r2STAbImG99GrDIBRuJPR8BQr1uKmxFSTSoGOZwkITqEfZy
boS/as58vWFNj+6Y7z4MxFYzyPSLAY+wkkuwwbeE+/aD71QXfSi151bD+B/iYOTd
iqHbWlSwtMTFomXcIqV7Dp0Ft08HV6R9E9Ukd0CVrRGxQJl12ekY3QjlYmIHGFbP
BFarcNi5QgTMHW7FM0aXOY3qUwn9T5wm30HmbUvBq66xo+mab1HUXYeSCQkRu0F7
+2qzM7fkB4Fuxroq9u+hFxet0dN2WtcKv/xqrHsPA0o4M0EVZytffArzF1p1P7t5
dvTo/bJP6lCK5rDUiZPjGHNUg6Q3frxU7R7rfVlkXnvv2AlzpCP6yFRnXp1dXVYp
cCRxf9sKeXu4vrHSLExhozbdrGi6aAqnRxrDG3JDsiPaqwZM6BWZANF71h9qtXVN
D5YTxAW50OkK7cEzTaxTtPgGjRI1oAIz9kCntA+pBAyE4U/y5HNKEBie5ENDtecd
yCck2HEbX63WSgYP48Kx/djiYK2he1b+ZN3Km33yGROcjOprzR75Ihs2S0q0E5Pz
jaVxHzcVWagKJuJgnNDZtZimXCrjAwluaJiBLgZJubqQycS07ZJcYK5Syw3jN4KK
uMaNR24lsUjeM+EGgfPjklsCAwEAAQ==
-----END PUBLIC KEY-----`;

const PUBLIC_KEY_DEVELOPMENT = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4s7HJBgDHf/DKGJ7IdUx
G0HJERWDxAL+m2FwJyxJetSJbESFjvcKWE0rYFiNR0swYOtCQr+SjTBUBn51nu4r
d1DgDTo7i6b7MpkhoRQxPI48P3vL1jENMoONi+raky5PA8wbBxNVTYWcX+yfIs75
3lXh86iUx/3e+J67C2W04JH6GjEDuhnlBYb3FprPRFTrl7sgTjvkNpn2NFw/OomX
1F7HENyfH7N8oGMM4xn3bW322bhMP7aY3aIT7EN/g5lppUtb7FXchMXYet+5ZTLr
LkCpyl4ZEeSnWUItCu30MaYbKIydCsPbFw9XgVMkq7M+ap3JDll6wBrOjgfyG71w
AMEbnRRA9yvFMOXOvpEdxdvjzPpKaRF8Uw6DG+CDD0RZnPAVQRQnNVFpHhueVhgp
ENJ8Oj4evjsR8fIfPIYs7bEusY2UIZVEi2euA+XJXp1S93v77ac8Kmp3srFqW+qo
5PBFl4yNpvPtNw7YfFdwoHEiyTVg3jbusSYCl4itABONdJA7Vmf6768eQZ6+39u+
75LOwOss2S+0c2aDJUc4/5dToKgR5UTzLSsLSawWJB4Lde1K6A6tgO1ylu8I4vAk
V9Mdo9bre2jAAYIk/g+0WM1r2ghPXpG5PjbwZR1mJ9RN04YtZ4zsK42Dr102gh3m
CR8USZqTDiGr3B1MWjug6csCAwEAAQ==
-----END PUBLIC KEY-----`;

export const AUTHENTICATION_CONSTANTS = {
  JWT_ISS: "userfront",
  TENANT_ID: "wn9rqvrb",
  JWT_ALG: "RS256",
  JWT_PUBLIC_KEY:
    process.env.NODE_ENV === "development"
      ? PUBLIC_KEY_DEVELOPMENT
      : PUBLIC_KEY_PRODUCTION,
  JOSE_ERRORS: {
    ERR_JOSE_ALG_NOT_ALLOWED: "algorithm not allowed",
    ERR_JOSE_GENERIC: "unknown error caught",
    ERR_JOSE_NOT_SUPPORTED: "this lib, feature or algorithm is not supported",
    ERR_JWE_DECRYPTION_FAILED: "decryption operation failed",
    ERR_JWE_INVALID: "invalid JWE",
    ERR_JWK_INVALID: "invalid JWK",
    ERR_JWKS_INVALID: "invalid JWKS",
    ERR_JWKS_MULTIPLE_MATCHING_KEYS: "JWKS, incorrect keys",
    ERR_JWKS_NO_MATCHING_KEY: "JWKS, no matching keys found",
    ERR_JWKS_TIMEOUT: "JWKS, response timed out",
    ERR_JWS_INVALID: "invalid JWS",
    ERR_JWS_SIGNATURE_VERIFICATION_FAILED: "signature verification failed",
    ERR_JWT_CLAIM_VALIDATION_FAILED: "JWT claim validation failure",
    ERR_JWT_EXPIRED: "JWT has expired",
    ERR_JWT_INVALID: "JWT is invalid",
  },
};
*/

export const DATE_CONSTANTS = {
  SERVER_TZ: "",
};

export const APP_CONSTANTS = {
  HEARTBEAT_MS: 10000,
};

export const MSG_BUS_ENDPOINT = {
  servers: ["msg.name-industry.com:8080"],
  clientNamePrefix: "web-client-",
  userNamePrefix: "web-user-"
};

export const MSG_BUS_MODULE_SETTINGS = {
  AUTO_CONNECT: true,
};
